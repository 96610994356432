<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center"
        :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`">
        <div class="left--side__wrapper p-3">
          <div class="wrapper__side">
            <div>
              <img class="mb-1" src="@/assets/images/logo.png" alt="logo" width="95">
              <hr class="w-25 m-0" style="border: 1px solid #E79242;">
              <div class="my-3">
                <h6 class="size14 fw-bold-400" style="line-height: 23px;">
                  Khusus di desain untuk manajemen Toko Bangunan. <br>
                  Praktis, mudah dan lengkap.
                </h6>
              </div>
            </div>
            <div>
              <h5 class="size14 fw-bold-400 mb-1">
                Sudah punya akun ?
              </h5>
              <h5 class="size16 fw-bold-600" @click="$router.push({ name: 'login' })" role="button">
                Masuk Sekarang
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="10" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="fw-bold-800 mb-1 text-dark size20">
            Masukan Kode OTP
          </b-card-title>
          <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-2">
            Masukan kode OTP yang kita kirim ke nomor handphone <b>{{ phoneNumber }}</b> via Whatsapp
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form" @submit.prevent>
              <!-- hp -->
              <b-form-group class="custom__form--input mb-2">
                <label for="otp">Kode OTP <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Kode OTP" rules="required">
                  <b-form-input id="otp" v-model="otp" type="text" name="otp" class="custom__input"
                    :state="errors.length > 0 ? false : null" placeholder="123456" maxlength="6" />
                  <small class="text-danger my-25">{{ errors[0] }}</small>
                  <div class="d-flex justify-content-between mt-25">
                    <a class="fw-bold-700 size14" disabled="true"
                      :class="countdown.expired ? 'text-primary dark' : 'text-dark'"
                      @click="countdown.expired ? getOtpCode() : ''">
                      Kirim Ulang Kode OTP
                    </a>
                    <div class="d-flex align-items-center">
                      <svg v-if="!countdown.expired" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.99102 1.66675C5.39102 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39102 18.3334 9.99102 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99102 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z"
                          fill="#333333" />
                        <path d="M10.416 5.83325H9.16602V10.8333L13.541 13.4583L14.166 12.4333L10.416 10.2083V5.83325Z"
                          fill="#333333" />
                      </svg>
                      <h6 v-if="!countdown.expired" class="mb-0 text-dark fw-bold-700 ml-25">
                        {{ countdown.minutes }} : {{ countdown.seconds }}
                      </h6>
                      <h6 v-else class="mb-0 text-danger fw-bold-700 ml-25">
                        Waktu habis
                      </h6>
                    </div>
                  </div>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button id="button--otp" type="submit" variant="secondary" block :disabled="(isLoading || otp == '')"
                class="size14 py-1 border-8 d-flex justify-content-center align-items-center" @click="validationForm">
                <div v-if="isLoading" class="d-flex justify-content-center mr-1">
                  <b-spinner small label="Loading..." />
                </div>
                Lanjutkan
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Jika ada kendala terkait login bisa </span>
            <a class="text-dark text-darken-6" :href="'https://wa.me/6281807070900'" target="_blank">
              &nbsp;hubungi admin
            </a>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      moment,
      sideImg: require('@/assets/images/background.png'),
      // validation rulesimport store from '@/store/index'
      required,
      isLoading: false,
      otp: '',
      otpResponse: null,
      verifications: {
        operator_phone: '',
        operator_phone_country: '',
        otp_type: '',
        otp_token: '',
      },
      countdown: {
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        expired: false,
      },
      phoneNumber: '',
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (localStorage.getItem('otp_type') === 'register') {
      this.getOtpCode()
    } else {
      const expiredAt = localStorage.getItem('expired_at')
      this.otpResponse = localStorage.getItem('otp_type')
      this.phoneNumber = userData?.phone || localStorage.getItem('operator_phone')
      const currentDate = new Date()
      const expireAt2 = moment(expiredAt).format('YYYY-MM-DD hh:mm:ss')
      const currentDateFormatted = moment(currentDate).format('YYYY-MM-DD hh:mm:ss')
      const timestamp1 = moment(expireAt2).format('X')
      const timestamp2 = moment(currentDateFormatted).format('X')
      this.countDown2(parseInt(timestamp1, 10), parseInt(timestamp2, 10))
    }
    //   let digit1 = Number(digit0)
    //   let phone
    //   if (digit1 == 0) {
    //     phone = val.substring(1)
    //     this.payment.handphone = this.item.value + phone
    //   } else {
    //     // phone = this.payment.handphone
    //     this.payment.handphone = this.item.value + val
    //   }
  },
  // ready() {
  //   window.onbeforeunload = this.leaving()
  // },
  beforeMount() {
    window.addEventListener('beforeunload', this.leaving())
  },
  methods: {
    leaving() {
      if (localStorage.getItem('otp_type')) {
        this.$router.push('/otp')
      }
    },
    async getOtpCode() {
      let handphone = ''
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.phoneNumber = userData.phone
      if (userData) {
        const digit0 = String(userData.phone).charAt(0)
        const digit1 = Number(digit0)
        if (digit1 === 0) {
          // phone = phone.substring(1)
          handphone = `62${userData.phone.substring(1)}`
        }
      }

      const operatorPhone = localStorage.getItem('operator_phone')
      const operatorPhoneCountry = localStorage.getItem('operator_phone_country')
      const otpType = localStorage.getItem('otp_type')
      const expiredAt = localStorage.getItem('expired_at')

      try {
        const response = await this.$http.post('/otp/generate', {
          operator_phone: userData?.phone || handphone || operatorPhone,
          operator_phone_country: userData?.phone_country.country_code || operatorPhoneCountry,
          otp_type: otpType || 'register',
        })
        localStorage.setItem('expired_at', response.data.data.expired_at)

        const currentDate = new Date()
        const expireAt2 = moment(response.data.data.expired_at || expiredAt).format('YYYY-MM-DD hh:mm:ss')
        const currentDateFormatted = moment(currentDate).format('YYYY-MM-DD hh:mm:ss')

        const timestamp1 = moment(expireAt2).format('X')
        const timestamp2 = moment(currentDateFormatted).format('X')

        this.countDown2(parseInt(timestamp1, 10), parseInt(timestamp2, 10))
      } catch (error) {
        console.log(error)
      }
    },
    countDown2(time1, time2) {
      this.countdown = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
        expired: false,
      }
      const eventTime = time1
      const currentTime = time2
      const diffTime = eventTime - currentTime
      let duration = moment.duration(diffTime * 1000, 'milliseconds')
      const interval = 1000
      const x = setInterval(() => {
        duration = moment.duration(duration - interval, 'milliseconds')
        this.countdown.hours = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours()
        this.countdown.minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes()
        this.countdown.seconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds()
        if (duration < 0) {
          this.countdown.expired = true
          clearInterval(x)
        }
      }, interval)
    },
    async validationForm() {
      this.isLoading = true
      const operatorPhone = localStorage.getItem('operator_phone')
      const operatorPhoneCountry = localStorage.getItem('operator_phone_country')
      const otpType = localStorage.getItem('otp_type')
      const fromPage = localStorage.getItem('from')
      try {
        const token = localStorage.getItem('temporary_token')
        const response = await this.$http.post('/otp/verification', {
          otp_token: this.otp,
          otp_type: otpType,
          operator_phone: operatorPhone,
          operator_phone_country: operatorPhoneCountry,
        })
        if (response.status === 200 && otpType === 'register' && fromPage !== 'login') {
          const userData = JSON.stringify(response.data.data)
          localStorage.setItem('userData', userData)
          useJwt.setToken(token)
          localStorage.removeItem('temporary_token')
          localStorage.removeItem('otp_type')
          localStorage.removeItem('expired_at')
          localStorage.removeItem('operator_phone')
          localStorage.removeItem('operator_phone_country')
          this.$router.push('/login')
          this.isLoading = false
        }
        if (response.status === 200 && fromPage === 'login') {
          const userData = JSON.stringify(response.data.data)
          localStorage.setItem('userData', userData)
          localStorage.removeItem('otp_type')
          localStorage.removeItem('expired_at')
          localStorage.removeItem('operator_phone')
          localStorage.removeItem('operator_phone_country')
          localStorage.removeItem('from')
          this.$router.push('/')
        }
        if (response.status === 200 && otpType === 'forgot_password') {
          localStorage.removeItem('operator_phone')
          localStorage.removeItem('operator_phone_country')
          localStorage.removeItem('otp_type')
          localStorage.removeItem('expired_at')
          this.$router.push({ name: 'change-password', query: { phone: operatorPhone, phone_country: operatorPhoneCountry, token: response.data.data.password_reset_token } })
        }
      } catch (error) {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.image__cover--login {
  border-radius: 0px 32px 32px 0px;
  object-fit: cover;
  height: 100vh;
}

.left--side__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(21, 38, 61, 0.2);
  backdrop-filter: blur(44.3121px);
  border-radius: 20.257px;
  width: 420px;

  .wrapper__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      font-family: 'Medula One', sans-serif !important;
      font-size: 50px;
    }

    h5,
    h6 {
      color: #fff;
      font-family: 'Be Vietnam Pro', sans-serif !important;
    }
  }
}
</style>
